import { FlyoutMenuItemProps } from 'lhs/components/flyout-menu-item';
import { config } from '../../../config/pendo.config.json';
import enUS from '../../../languages/en_US';

export enum AdminToolsPaths {
  COURSES = 'institution-dashboard',
  LEARNING_JOURNEYS = 'learning-journeys',
  CONTENT_LIBRARY = 'content-library-collections',
  LEARNING_ANALYTICS = 'analytics-dashboard',
  LICENSE_ENROLLMENT = 'license-dashboard',
  LICENSE_ANALYTICS = 'license-analytics',
  USER_ENROLLMENT_DATA = 'license-dashboard',
  USER_MANAGEMENT = 'institution-roles',
  SKILL_TAGS = 'skill-tags',
  EMAIL_TEMPLATE = 'institution-email-template',
  ADVANCED_SETTINGS = 'institution-settings',
  MENTORING_PROGRAMS = 'mentoring-programs',
}

type FlyoutMenuCategory = {
  label: string;
  items: FlyoutMenuItemProps[];
};
/* @ngInject */
export function getAdminToolsFlyoutItems(
  t: typeof enUS,
  CurrentPermissionsManager,
  hasLicenseDashboard,
  openModal,
  isCurrentCourseIsCollection,
  institution,
) {
  const isOrgAdmin = CurrentPermissionsManager.hasOrgAdminPermissions();
  const isCourseManager = CurrentPermissionsManager.hasCourseManagerPermissions();
  const adminToolsFlyoutCategories: FlyoutMenuCategory[] = [];

  let learnPlusItems = [
    {
      label: t.INSTITUTIONS.ADMIN_CONTROLS.COURSES(),
      icon: 'course',
      path: AdminToolsPaths.COURSES,
      dataQa: config.pendo.lhs.adminTools.courses,
    },
    {
      label: t.INSTITUTIONS.ADMIN_CONTROLS.LEARNING_JOURNEYS(),
      icon: 'path',
      path: AdminToolsPaths.LEARNING_JOURNEYS,
      dataQa: config.pendo.lhs.adminTools.learningJourneys,
    },
    {
      label: t.INSTITUTIONS.ADMIN_CONTROLS.CONTENT_LIBRARY(),
      icon: 'content',
      path: AdminToolsPaths.CONTENT_LIBRARY,
      dataQa: config.pendo.lhs.adminTools.contentLibrary,
      isHighlightState: ($state) => (
        ($state.includes('content-library-collections-parent') || ($state.includes('lecture-page')
        && isCurrentCourseIsCollection))
      ),
    },
    {
      label: t.INSTITUTIONS.ADMIN_CONTROLS.LEARNING_ANALYTICS(),
      icon: 'data',
      path: AdminToolsPaths.LEARNING_ANALYTICS,
      dataQa: config.pendo.lhs.adminTools.learningAnalytics,
    },
  ];

  if (isOrgAdmin && isCourseManager && hasLicenseDashboard) {
    learnPlusItems = [
      ...learnPlusItems,
      {
        label: t.INSTITUTIONS.ADMIN_CONTROLS.LICENSE_ANALYTICS(),
        icon: 'enrollment',
        path: AdminToolsPaths.LICENSE_ANALYTICS,
        dataQa: config.pendo.lhs.adminTools.licenseDashboard,
      },
    ];
  }

  if (learnPlusItems.length) {
    adminToolsFlyoutCategories.push({
      label: t.LHS.ORG_ADMIN_TOOLS.LEARN_PLUS(),
      items: learnPlusItems,
    });
  }

  let mentorPlusItems = [];

  if (institution.isMentorshipEnabled && isOrgAdmin) {
    mentorPlusItems = [
      ...mentorPlusItems,
      {
        // TODO: This icon will change, using this one for now
        icon: 'course',
        label: t.MENTORING_PROGRAMS.ONE_ON_ONE_PROGRAMS(),
        path: AdminToolsPaths.MENTORING_PROGRAMS,
        dataQa: config.pendo.lhs.adminTools.mentoringPrograms,
      },
    ];
  }

  if (mentorPlusItems.length) {
    adminToolsFlyoutCategories.push({
      label: t.LHS.ORG_ADMIN_TOOLS.MENTOR_PLUS(),
      items: mentorPlusItems,
    });
  }

  let platformSettingsItems = [];

  if (isOrgAdmin && isCourseManager) {
    platformSettingsItems = [
      ...platformSettingsItems,
      {
        label: t.INSTITUTIONS.ADMIN_CONTROLS.USER_ENROLLMENT_DATA_DOWNLOADS(),
        icon: 'download',
        path: AdminToolsPaths.LICENSE_ENROLLMENT,
        dataQa: config.pendo.lhs.adminTools.userEnrollmentDataDownloads,
        customOnClick: openModal,
      },
      {
        label: t.INSTITUTIONS.ADMIN_CONTROLS.USER_MANAGEMENT(),
        icon: 'managing-roles',
        path: AdminToolsPaths.USER_MANAGEMENT,
        dataQa: config.pendo.lhs.adminTools.orgLevelUserManagement,
      },
      {
        label: t.INSTITUTIONS.ADMIN_CONTROLS.SKILL_TAGS(),
        icon: 'skill-tag',
        path: AdminToolsPaths.SKILL_TAGS,
        dataQa: config.pendo.lhs.adminTools.skillTags,
      },
    ];
  }

  if (isOrgAdmin) {
    platformSettingsItems = [
      ...platformSettingsItems,
      {
        label: t.INSTITUTIONS.ADMIN_CONTROLS.EMAIL_TEMPLATE(),
        icon: 'email-template',
        path: AdminToolsPaths.EMAIL_TEMPLATE,
        dataQa: config.pendo.lhs.adminTools.emailTemplate,
      },
      {
        label: t.INSTITUTIONS.ADMIN_CONTROLS.ADVANCED_SETTINGS(),
        icon: 'settings',
        path: AdminToolsPaths.ADVANCED_SETTINGS,
        dataQa: config.pendo.lhs.adminTools.advancedSettings,
      },
    ];
  }

  if (platformSettingsItems.length) {
    adminToolsFlyoutCategories.push({
      label: t.LHS.ORG_ADMIN_TOOLS.PLATFORM_SETTINGS(),
      items: platformSettingsItems,
    });
  }

  return adminToolsFlyoutCategories;
}

export const adminToolsRelatedPaths = [
  AdminToolsPaths.COURSES,
  AdminToolsPaths.LEARNING_JOURNEYS,
  AdminToolsPaths.LEARNING_ANALYTICS,
  AdminToolsPaths.LICENSE_ENROLLMENT,
  AdminToolsPaths.USER_MANAGEMENT,
  AdminToolsPaths.SKILL_TAGS,
  AdminToolsPaths.EMAIL_TEMPLATE,
  AdminToolsPaths.ADVANCED_SETTINGS,
  AdminToolsPaths.CONTENT_LIBRARY,
  AdminToolsPaths.LICENSE_ANALYTICS,
  AdminToolsPaths.MENTORING_PROGRAMS,
];

export default getAdminToolsFlyoutItems;
